import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../../components/Layout'
import Section from '../../components/Section'


import recruitShopLogo from '../../images/recruit-shop/recruit-shop-logo.png';
import '../recruit-shop.scss'

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '44c5372f-fedc-4467-9e7b-3a93d160854d',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}


export default () =>
  <Layout
    className="RecruitShop"
    title="Recruit Shop">

    <Section>
      <Container>
        <Row>
          <Col md={7} className="d-flex flex-column left-content">
            <img src={recruitShopLogo} className="logo" />
            <h1>Affordable recruitment services</h1>
            <div className="flat-fee">Flat fee of $2495</div>

            <div className="intro">
              <p>MyHR has partnered with Recruit Shop to remove the admin and hassle from recruitment so businesses can focus on hiring the best people.</p>
            </div>

            <h2>The recruitment process:</h2>

            <p>
              <b>1. Market vacancy</b><br/>
              Recruit Shop will write and market your job vacancy, then publish it across all major job boards (including Seek), social media networks, and our database. Your role will be marketed multiple times for up to 2 months.
            </p>

            <p>
              <b>2. Screen and shortlist</b><br/>
              Stay informed throughout the recruitment process with regular updates. All applications will be reviewed and top candidates asked relevant questions to assess their suitability.
            </p>

            <p>
              <b>3. Back to you – interview and make your hire</b><br/>
              Conduct interviews internally and fill your vacancy with a great new team member. Let Recruit Shop know if you weren’t able to make a hire, we will rerun the campaign for 4 weeks for free, or give you $1000 back.
            </p>
          </Col>
          <Col md={5}>
            <div className="rightBox">
              <HubspotForm />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>

  </Layout>

